.header-title {
  color: #493971;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li a {
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #493971;
  color: #fff;
}

.pagination-reports {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
}

.pagination-reports li a {
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.pagination-reports li.active a {
  background-color: #493971;
  color: #fff;
}

.pagination-req {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 30px;
  width:100%;
}

.pagination-req li a {
  color: #667085;
  text-decoration: none;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 14px;
 }

.pagination-req li.active a {
  background-color: #F9F5FF;
  border-radius: 5px;
  padding: 12px 16px;
}

.pagination-req > li:first-child {
  margin-right: auto;
}

.pagination-req > li:last-child {
  margin-left: auto;
}

.pagination-req > li:not(:first-child):not(:last-child) {
  display: inline-block;
  margin: 0 5px;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.2;
  color: #ccc;
}

/*Css for leave approval page*/
.leave-approval {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leave-approval-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
  z-index: 0;
  margin-top:50px;
}

/*Css for leave request page*/
.leave-request-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x:auto;
}

/*Css for WFH approval page*/
.wfh-approval-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wfh-approval-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
  z-index: 0;
  margin-top:50px;
}

/*Css for wfh request page*/
.wfh-request-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wfh-request-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px;
  overflow: hidden;
  z-index: 0;
  margin-top:50px;
}

.leave-approval-table th {
  background-color: #f0f6ff;
  color: #191919;
  padding:15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: center;
}

.leave-approval-table td {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.76px;
  color: #4C4C4C;
}

.leave-approval-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.leave-approval-table tbody tr {
  border-bottom: 1px solid #ddd;
  height:70px;
}

.leave-approval-buttons {
  display: flex;
  gap: 3px;
}

.leave-approval-reject-button {
  background-color: #f94141;
  color: white;
  border: 2px solid;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.leave-approval-approve-button {
  background-color: #49e945;
  color: white;
  border: 2px solid;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.wfh-approval-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.wfh-approval-approve-button{
  background-color: #49e945;
  color: white;
  border: 2px solid;
  padding: 3px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.wfh-approval-reject-button{
  background-color: #f94141;
  color: white;
  border: 2px solid;
  padding: 3px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.wfh-request-table th {
  background-color: #f0f6ff;
  color: #191919;
  padding:15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: center;
}

.wfh-request-table td {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.76px;
  color: #4C4C4C;
}

.wfh-request-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.icon-pagination {
  margin: 0 5px;
}

.next-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: visible;
}

.button-margin-right {
  margin-right: 7px;
}

.file-upload-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.leave-popup-error-message {
  color: red !important;
  font-size: 14px;
  text-align: left !important;
  margin-left: 10px;
}

.leave-popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.leave-popup-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.leave-popup-label {
  display: block;
  margin-bottom: 10px;
}

.leave-popup-input,
.leave-popup-select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.leave-popup-buttons {
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.leave-popup-submit,
.leave-popup-close {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.leave-popup-submit {
  background-color: #4caf50;
  color: #fff;
}

.leave-popup-close {
  background-color: #f44336;
  color: #fff;
}

/* style for approve and reject button */

.reject-button {
  background-color: #f94141;
  color: white;
  border: 2px solid;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.approve-button {
  background-color: #49e945;
  color: white;
  border: 2px solid;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.approve-button:hover {
  background-color: #008000;
  color: white;
}

.reject-button:hover {
  background-color: #cc0000;
  color: white;
}

/* style for input field in pop */

.input-style{
    border: 2px solid #3498db;
    padding: 8px;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out
}

.input-style:focus{
    border-color: #2ecc71;
}
.popup-content {
  width: auto;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
