@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: local('Nunito'), local('Nunito-Italic'),
       url('../../fonts/Nunito-VariableFont_wght.ttf') format('truetype');
}

body, html, h1, a, .nav-text {
  font-family: "Nunito", sans-serif;
}

/* Reset styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Navigation styles */
nav {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

nav ul,
nav ul li {
  outline: 0;
}

nav ul li a {
  text-decoration: none;
}

/* Body styles */
.profile-page{
    font-family: "Roboto", sans-serif;
    color: rgb(33, 37, 41);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;

}

/* Main container styles */
main {
  min-height: 100vh;
  width: 100vw;
  z-index: 10;
  margin: 0;
}


/* Main menu styles */
.main-menu {
  background: rgba(34, 45, 100, 255);
  padding-top: 20px;
  font-family: "Roboto", sans-serif;
  position: fixed;
  height:100%;
  z-index: 1000;
  left: 0;
  transition: width 0.5s ease-in-out;
  border-bottom-right-radius: 20px ;
  display: flex;
  flex-direction: column;
}

/* Sidebar content */
.sidebar-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Scrollbar styles */
.sidebar-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 100, 100, 0.8) rgba(34, 45, 100, 0.3);
}

.main-menu.open {
  width: 220px;
  max-width: 100%;
}

.main-menu.open .nav-text {
  display: block;
}

.main-menu:not(.open) + .main-content {
  margin-left: 2% ;
  padding-left:16px;
  width:98.3%;
}

.main-menu:not(.open) {
  width: auto;
  border-bottom-right-radius: 20px;
}

.main-menu:not(.open) .nav-text {
  display: none;
}

.main-menu:not(.open) .nav-icon {
  padding-left: 0.8rem !important;
}

.main-menu:not(.open) .nav-ul {
  padding-left: 0;
  padding-top: 18px;
}

.main-menu:not(.open) .nav-item.active a {
  padding-left: 0;
}

.main-menu:not(.open) .nav-item a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-menu:not(.open) + .right-content {
  margin-left: 2%;
}

.main-menu h1 {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin: 20px 0 30px;
  color: #fff;
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  main {
    height: 100vh;
    width: 100vw;
  }

  .main-content {
    height: 100vh;
    width: 100vw;
    align-items: start !important;
    margin-top: 87px !important;
    margin-left: 120px !important;
  }

  .main-menu:not(.open) + .main-content {
    margin-left: 3% !important;
  }
  .main-menu.open + .main-content {
    margin-left: 150px !important;
    width: 87%;
  }
  .main-menu.open {
    width: 189px;
    height:100%;
  }
  .main-menu.open .nav-ul {
    margin-top: 16px !important;
  }

}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .main-content {
    margin-left: 145px !important;
    width: 100vw;
    height: 160vh;
  }
  .main-menu.open {
    width: 189px;
  }
  .nav-item a {
    margin-left: 1% !important;
  }
  .nav-item.active a {
    padding-left: 8%;
  }
  .main-menu:not(.open) + .main-content {
    margin-left: 2% !important;
  }
  .main-menu.open + .main-content {
    width:91%;
  }
}

@media screen and (min-width: 451px) and (max-width: 600px) {
  main {
    grid-template-columns: 22% 78%;
    height: 100%;
  }
  .main-menu {
    width: 86px;
    height: 200%;
  }
  .right-content {
    width: 175vw !important;
    height: 200vh;
    align-items: start !important;
    margin-top: 80% !important;
  }
  .main-menu h1 {
    font-size: 1.0rem;
  }
  .nav-item a {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 450px) {
  main {
    grid-template-columns: 30% 70%;
    height: 100vh;
    width: 100vw;
  }
  .main-menu {
    width: 108px;
    height: 200%;
  }
  .right-content {
    height: 394vh;
    width: 313vw !important;
    align-items: start !important;
    margin-top: 71% !important;
  }
  .main-menu h1 {
    font-size: 1.0rem;
  }
  .nav-item a {
    font-size: 0.5rem;
  }
}
/* Logo styles */
.logo {
  display: none;
}

/* Navigation item styles */
.nav-item {
  position: relative;
  display: block;
  padding-top:10px;
  text-align:left;
}

.nav-item a {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-size: 1rem;
  padding: 15px 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* Navigation item pseudo-elements */
.nav-item b:nth-child(1),
.nav-item b:nth-child(2) {
  position: absolute;
  height: 15px;
  width: 100%;
  background: #fff;
  display: none;
}

.nav-item b:nth-child(1)::before,
.nav-item b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(73, 57, 113);
}

.nav-item b:nth-child(1)::before {
  border-bottom-right-radius: 20px;
}

.nav-item b:nth-child(2)::before {
  border-top-right-radius: 20px;
}

/* Active state styles */
.nav-item.active b:nth-child(1),
.nav-item.active b:nth-child(2) {
  display: block;
}

.nav-item.active a {
  color: #242D67;
  background: rgb(254, 254, 254);
  width:auto;
}


/* Navigation icon styles */
.nav-icon {
  width: 20px;
  height: 20px;
  font-size: 25px;
  text-align: center;
  margin-right: 10px;
  margin-left:10px;
}

.arrow-nav-icon{
  color: #FFFFFF;
  float: right;
  font-size: 23px;
  margin-right: 10px;
}
/* Navigation text styles */
.nav-text {
  display: inline-block;
  width: auto;
  height: auto;
  font-weight: 600;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  padding-right:5px;
}

/* Left content styles */
.right-content {
  background: #f6f7fb;
  min-height: 100vh;
  background-color: #FFFFFF;
  display:flex;
  position: relative;
  margin-top: 1rem;
  margin-left: 42px;
}

/* Logout button styles */

.logout-button {
  background-color: #222d64;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  position: fixed;
  top: 20px;
  right:83px;
  z-index: 1000;
  cursor: pointer;
  margin-top: 8px
}

.logout-button:hover {
  background-color: #493971;
}


.left-content {
  padding-top: 65px;
  padding-left: 0;
  padding-right:0;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px;
}

.user-table {
  width: 100%;
  min-width: 5%;
  border-collapse: collapse;
  position: absolute;
  left: 0;
  padding-bottom: 2100px;
  z-index: 0;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  color: #000;
}

.user-table th {
  background-color: #f2f2f2;
}

/*home page nav*/


.full-screen-container {
  display: flex;
  height: 100vh;
  width : 100vw;
}

.navigation-container {
  width: 200px;
  background-color: #333;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
}

.content-container {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  color: #000000;
}

.user-home-container{
  height: 100vh;
  padding: 0;
  position: absolute;
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

}

/*profile picture*/

.profile-photo {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.profile-photo-modal {
  height: 200px;
  width: 200px;
}

.profilephoto-input {
  width: 300px;
}

.btn-profile {
  border: 1px solid #000;
  width: 150px;
  color: white;
  height : 37px;
  background-color: #1b2d60f2;
  text-align: center;
  padding: 1px;
}

.profile-username {
  position: fixed;
  top: 20px;
  right: 250px;
  color: #fff;
  margin-left: 10px;
  margin-top: 20px;
}

.user-profile-photo {
  border-radius: 50%;
  width: 50px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}

/*Sticky header on top*/
.sticky-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(34, 45, 100);
  padding: 0px 30px;
  z-index: 1000;
  height: 70px;
  top: 0;
  position: fixed;
}

.title {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 20px 0 20px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.profile-section {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
}

.menu-button {
  background: none;
  border: white;
  cursor: pointer;
  padding: 20px;
  color: white;
  width:  10px;
  height: 100px;
  margin-right: 100px;
  font-size: 30px;
}

.nav-parent {
  position: sticky;
}

/*user-report table*/

.reports-heading {
  font-size: 24px;
  color: #242D67;
  margin-bottom: 0;
  margin-right: 50px;
  padding-right: 50px;
  position: absolute;
  top: 80px;
}

.pagination-wrapper {
  margin-top:20px;
}

.main-content {
  margin-left: 188px;
  margin-top: 87px;
  box-shadow: none !important; /* Remove the box shadow */
  border: none !important; /* Remove the border */
}

.main-menu.open .nav-ul {
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 3px;
}

.allusers-edit-button{
  background-color: #222d64;
  color: white;
  padding: 6px 16px;
  font-size: 16px;
  width:100%;
}

.open-image-container {
  margin-top:-24px;
  padding: 3.5rem;
  background-image: url('/assets/images/ATDXT-Logo-envelope.png');
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
}

.close-image-container {
  margin-top:44px;
  padding: 2rem;
  background-image: url('/assets/images/atmini.jpeg');
  background-size: cover;
  background-position: center;
}

.home-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.home-image-container.active {
  background-image: url('/assets/images/blue/Vector.png');
}

.main-menu:not(.open) .home-image-container {
  padding-left: 4rem !important;
}

.activeEmployee-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 7.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.activeEmployee-image-container.active {
  background-image: url('/assets/images/blue/Group 7.png');
}

.main-menu:not(.open) .activeEmployee-image-container {
  padding-left: 4rem !important;
}

.addEmployee-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 8.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.addEmployee-image-container.active {
  background-image: url('/assets/images/blue/Group 8.png') !important;
}

.main-menu:not(.open) .addEmployee-image-container {
  padding-left: 4rem !important;
}

.addHoliday-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 12.png');
  background-size: 22px 27px;
  background-repeat: no-repeat;
  background-position: center;
}

.addHoliday-image-container.active {
  background-image: url('/assets/images/blue/Group 12.png');
}

.main-menu:not(.open) .addHoliday-image-container {
  padding-left: 4rem !important;
}

.downloadsalaryslip-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 9.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.downloadsalaryslip-image-container.active {
  background-image: url('/assets/images/blue/Group 9.png');
}

.main-menu:not(.open) .downloadsalaryslip-image-container {
  padding-left: 4rem !important;
}

.uploadsalaryslip-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector-1.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.uploadsalaryslip-image-container.active {
  background-image: url('/assets/images/blue/Vector-1.png');
}

.main-menu:not(.open) .uploadsalaryslip-image-container {
  padding-left: 4rem !important;
}

.leavereports-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group.png');
  background-size: 18px 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.leavereports-image-container.active {
  background-image: url('/assets/images/blue/Group.png');
}

.myreports-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group 13.png');
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.myreports-image-container.active {
  background-image: url('/assets/images/blue/Group 13.png');
  background-size: 32px 32px;
}


.main-menu:not(.open) .leavereports-image-container {
  padding-left: 4rem !important;
}

.main-menu:not(.open) .myreports-image-container {
  padding-left: 4rem !important;
}

.exemployees-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Group-1.png');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.exemployees-image-container.active {
  background-image: url('/assets/images/blue/Group-1.png');
}

.main-menu:not(.open) .exemployees-image-container {
  padding-left: 4rem !important;
}

.empapproval-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector-2.svg');
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.empapproval-image-container.active {
  background-image: url('/assets/images/blue/Vector-2.svg');
}

.main-menu:not(.open) .empapproval-image-container {
  padding-left: 4rem !important;
}
.main-menu:not(.open) .right-arrow-nav-icon {
  color: #FFFFFF;
  font-size: 23px;
  margin-top:10px;
}
.menu-username {
  padding: 8px 12px;
  color: #000;
  font-weight: bold;
}

.swappedholidays-image-container {
  margin-top:0px;
  padding: 1rem;
  background-image: url('/assets/images/white/Vector-3.png');
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: center;
}

.swappedholidays-image-container.active {
  background-image: url('/assets/images/blue/Vector-3.png');
}

.main-menu:not(.open) .swappedholidays-image-container {
  padding-left: 4rem !important;
}


/* Custom styles for the pie chart container */
.custom-pie-chart-container {
    width: 100%;
    max-width: 680px;
    height: auto;
    border-radius: 20px;
    margin-top: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    padding: 20px;
    box-shadow: 4px 7px 21px 1px #00000033;
    margin: 3.8rem auto;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
}


/* Custom styles for the pie chart card */
.custom-pie-chart-card {
	width: 90%;
	height: 350px;
	background-color: #ffffff;
	border-radius: 20px;

}

/* Custom styles for the PieChart component */
.custom-pie-chart {
	max-width: 100%;
	/* Optionally, set a max-width to limit the size on larger screens */
	margin-top: -50px;
	/* Adjust margin-top as needed */
}


/* Custom styles for the Tooltip component */
.custom-pie-chart .recharts-tooltip-wrapper {
	font-family: Arial, sans-serif;
	font-size: 12px;
	font-weight: bold;
	background: linear-gradient(45deg, #FF6347, #66CDAA);
	border: none;
	border-radius: 20px;
	text-align: center;
}

.custom-pie-chart .recharts-tooltip-label {
	color: #fff;
}

/* Custom styles for the Legend component */
.custom-pie-chart .recharts-legend-wrapper {
	text-align: right;
}

.custom-pie-chart .recharts-legend-item {
	color: #242D67;
}

.chart-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.chart-container {
  width: 100%;
  height: auto;
  position: relative;
}
.chart-container canvas {
  width: 300px;
  height: 300px;
}

.chart-label {
	font-size: 25px;
	font-weight: bold;
	text-align: center;
	color: #242D67;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
	.custom-pie-chart .recharts-legend-wrapper {
		text-align: center;
		/* Center legends horizontally */
		margin-top: 5px;
		/* Add margin to separate legends from the chart */
	}

	.custom-pie-chart .recharts-legend-item {
		display: block;
		margin-bottom: 5px;
	}

	.custom-pie-chart-container {
		padding: 15px;
	}

	.custom-pie-chart-card {
		max-width: 100%;
		height: auto;
	}

	.custom-pie-chart {
		max-width: 330%;
		/* Adjust the percentage value as needed for smaller screens */
		height: auto;
		/* Ensure the height adjusts based on content */
	}
}

@media only screen and (max-width: 480px) {
	.custom-pie-chart-container {
		padding: 10px;
		/* Further reduce padding */
	}

	.custom-pie-chart-card {
		font-size: 0.8rem;
		/* Adjust font size for smaller screens (optional) */
	}
}

.piechart-container {
  top: 309px;
  margin-top :70px;
  width: auto;
  min-width: 90%;
  margin: 3.8rem auto;
  display: inline-block;
}

@media (max-width: 767px) {
  .piechart-container,.pie-chart-container {
    margin-top: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .piechart-container {
    padding-right:15px;
    margin-top: 1.5rem;
  }
}
.custom-legend {
  list-style-type: none;
  padding: 0;
}

.custom-legend-item {
  color: #242d67;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.custom-legend-icon {
  width: 9px;
  height: 9px;
  margin-right: 8px;
}

.custom-legend-value.inactive {
  opacity: 0.5;
}

.custom-card-body {
  padding: 1.25rem;
}

.custom-card-title {
  font-size: 1.50rem;
  color: #242d67;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.leave-detailsReport {
  margin: 6rem auto;
  width: 100%;
  gap:0px !important;
}

.leave-detailsReport table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.leave-detailsReport table th,
.leave-details table td {
  padding: 12px 15px;
  text-align: center;
  vertical-align: middle;
}

.leave-detailsReport table thead th:first-child {
  border-top-left-radius: 12px;
}

.leave-detailsReport table thead th:last-child {
  border-top-right-radius: 12px;
}

.leave-detailsReport table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 12px;
}

.leave-detailsReport table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.leave-detailsReport table th {
  background-color: #242D67;
  color: #fff;
  font-weight: bold;
}

.leave-detailsReport table td {
  background-color: #f9f9f9;
  color: #333;
}

@media (max-width: 768px) {
  .leave-details {
    width: 100%;
  }

  .leave-detailsReport table th,
  .leave-detailsReport table td {
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .leave-detailsReport table th,
  .leave-detailsReport table td {
    padding: 5px;
  }
}

.pie-chart-container {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15px;
}

.outer-circle {
  position: absolute;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #242D67;
  clip-path: circle(50% at 50% 50%);
}

.inner-circle {
  position: absolute;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #D3BDFF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
.lop-tooltip {
  position: absolute;
  width: 40%;
  height: 10%;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  z-index: 10;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.small-circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #D3BDFF;
  margin-right: 5px;
}

.lop-legend {
  position: absolute;
  top: 94%;
  transform: translate(-100%);
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #808080;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.chart-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px 20px 20px 20px;
  opacity: 1px;
  box-shadow: 1px 3px 10px 1px #00000033;
  padding:20px;
}

.chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 150px;
  margin: 10px;
  flex-grow: 1;
}

.no-data-message {
  font-size: 23px;
  padding-left :500px;
}

.custom-pie-chart-container svg:focus,
.custom-pie-chart-container path:focus,
.custom-pie-chart-container circle:focus {
  outline: none;
}

.custom-pie-chart-container svg:focus-visible,
.custom-pie-chart-container path:focus-visible,
.custom-pie-chart-container circle:focus-visible {
  outline: none;
}

.loading-container-pieChartData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 100%;
  position: absolute;
  top: 150px;
  left: 0;
  background-image: url('/assets/images/Loading.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
}
