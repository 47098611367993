/* EditUserPage.css */
@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-VariableFont_opsz,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.edit-user-page-body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.edit-user-page-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vh;
  padding-bottom: 10%;
}

.edit-form-container {
  background-color: #f2e6ff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100vh;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-form strong {
  display: block;
  color: #344054;
  margin-bottom: 5px;
  color: black;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.edit-form input,
.edit-form select {
  width: 90%;
  height:50px;
  box-sizing: border-box;
  padding:  10px;
  margin-bottom: 8px;
  border-radius: 5px !important;
  margin: 0 auto;
  border: 1px solid #D0D5DD;
  background: #FFFFFF;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  margin-left:11px;
}

.edit-form input[type="number"], .edit-form input[type="date"],
.edit-form select * {
  font-size: 14px;
  border-radius: 5px !important;
  height:50px;
}

.input-field{
  margin-bottom: 12px;
  padding-left: 20px;
}

.textbox {
  width: 70%;
  height: 50px;
  border: 1px solid #D0D5DD;
  font-family: Inter;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
 }

.edit-button {
  background-color: #222d64 !important;
  color: white !important;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  top: 40%;
  right: 45%;
  width:130% !important;
  margin-bottom:20px;
}

.region-container {
   margin-left:100px;
   margin-bottom:20px;
}

.region-label{
  align-items:left !important;
  margin-right:600px;
  border-radius: 5px !important;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.region-select-container {
  position: relative;
  border-radius: 5px !important;
  width:auto !important;
}

.region-select-header {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #D9D9D966;
  width: 94%;
  height: 50px;
  text-align:left;
  font-size: 16px;
  border-radius:5px;
  border: 1px solid #D0D5DD;
  background: #FFFFFF;
}

.region-select-text-wrapper {
  flex-grow: 1;
  overflow-x: auto;
  white-space: nowrap;
  width:100%;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.region-select-text {
  white-space: nowrap;
  overflow: hidden;
}

.region-select-arrow {
  margin-left: 10px;
}

.region-select-dropdown {
  position: absolute;
  border: 1px solid #ccc;
  border-top: none;
  width: 510px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  z-index: 1;
}

.region-select-option {
  padding: 10px;
  display: flex;
  align-items: center;
}

.region-select-option input {
  margin: 0;
  margin-right: 10px;
  width: 14px;
  height: 14px;
}

.region-select-option label {
  margin: 0;
  padding-left: 5px;
  display: inline-block;
  margin-top:-10px;
}

@media screen and (max-width:1000px) {
    .edit-profile {
        width:90% !important;
    }
}

@media screen and (min-width:1001px) and (max-width:1310px) {
    :root {
        --edit-form-width: 85vw !important;
    }

    .input-field{
        width: calc((100vw - var(--edit-form-width)) * 1.3) !important;
    }

    .region-select-header {
        width: calc((100vw - var(--edit-form-width)) * 1.18) !important;
    }

    .region-select-dropdown {
        width: calc((100vw - var(--edit-form-width)) * 1.18) !important;
    }

    .edit-button {
        margin-right:calc((100vw - var(--edit-form-width)) * 0.2) !important;
        width:auto !important;
    }

    .edit-button:first-child {
        margin-right:-35px !important;
    }

    .edit-profile {
        width:97% !important;
    }
}

@media screen and (min-width: 1311px) and (max-width:1600px) {
    :root {
        --edit-form-width: 90vw !important;
    }

    .input-field{
        width: calc((100vw - var(--edit-form-width)) * 2.1) !important;
    }

    .region-select-header {
        width: calc((100vw - var(--edit-form-width)) * 1.91) !important;
    }

    .region-select-dropdown {
        width: calc((100vw - var(--edit-form-width)) * 1.91) !important;
    }

    .edit-profile {
        width:95% !important;
    }
}

@media screen and (min-width: 1601px) and (max-width:1800px){
    :root {
        --edit-form-width: 95vw !important;
    }

    .edit-form {
        width: calc((100vw - var(--edit-form-width)) * 15.6) !important;
    }

    .input-field{
        width: calc((100vw - var(--edit-form-width)) * 4.1) !important;
    }

    .region-select-header {
        width: calc((100vw - var(--edit-form-width)) * 3.7) !important;
    }

    .region-select-dropdown {
        width: calc((100vw - var(--edit-form-width)) * 3.7) !important;
    }

    .edit-profile {
        width:96% !important;
    }
}

@media screen and (min-width: 1801px) {
    :root {
       --edit-form-width: 96vw !important;
    }

    .edit-form {
       width: calc((100vw - var(--edit-form-width)) * 18.6) !important;
       margin-left:calc((100vw - var(--edit-form-width)) * 1.5) !important;
    }

    .input-field{
        width: calc((100vw - var(--edit-form-width)) * 5) !important;
    }

    .region-select-header {
       width: calc((100vw - var(--edit-form-width)) * 4.2) !important;
    }

    .region-select-dropdown {
       width: calc((100vw - var(--edit-form-width)) * 4.2) !important;
    }

    .edit-profile {
       width:97% !important;
    }
}

.error-message{
  color: red !important;
  font-size:14px;
  text-align:left !important;
  margin-left:10px
}

.span-style{
  color: red !important;
}

.select-box {
  padding: 1rem !important;
  border: none;
  border-radius: 8px !important;
}

.department-border{
  border-radius: 5px !important;
}

.edit-profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 10px;
  background: #F0F6FF;
  border: 1px solid #EAECF0;
  width: 97%;
}

.edit-employee-info {
  display: flex;
  align-items: center;
}

.edit-profile-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  margin-top:10px;
  margin-bottom:10px;
}

.name-job-department {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name-element {
  color: #344054;
  margin-right:50px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.job-department {
  display: flex;
  align-items: center;
}

.job-position {
  color:#667085;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.department {
  color:#667085;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.custom-col {
  width: 50%;
}

.edit-margin {
  margin-left:-10px;
}

.pointer-cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.edit-success {
  color:green;
  margin-top:35px;
  margin-bottom:50px;
}

.edit-error {
  color:red;
  margin-top:35px;
  margin-bottom:50px;
}

.custom-bottom {
  margin-bottom:100px;
}

.address-sections {
  border-radius: 20px ;
  box-shadow: 2px 3px 4px 1px #00000033;
  margin-top:20px;
  margin-bottom:20px;
  padding-bottom:20px;
  padding-top:20px;
  margin-right: 40px;
}

.search-result {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  position: absolute;
  z-index: 1;
  width: 19.5%;
  text-align: left;
  border-radius: 8px;
  margin-left: 11px !important;
}

.search-item {
  padding: 10px;
  cursor: pointer;
}

.search-item:hover {
  background-color: #f0f0f0;
}

.no-managers-found {
  padding: 10px;
  color: red;
}

.AllEditManager {
  width:17px !important;
  height: 17px !important;
  margin-left : -195px !important;
}

.AllManagers {
  margin-left : 5px;
}

.dropdown-container{
  margin-left :-15px !important;
}
.dropdown-container input{
  width : 97% !important;
}