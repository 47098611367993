.header-title {
  color: #493971;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li a {
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #493971;
  color: #fff;
}

.pagination-reports {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
}

.pagination-reports li a {
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.pagination-reports li.active a {
  background-color: #493971;
  color: #fff;
}

.pagination-req {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 30px;
  width:100%;
}

.pagination-req li a {
  color: #667085;
  text-decoration: none;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 14px;
 }

.pagination-req li.active a {
  background-color: #F9F5FF;
  border-radius: 5px;
  padding: 12px 16px;
}

.pagination-req > li:first-child {
  margin-right: auto;
}

.pagination-req > li:last-child {
  margin-left: auto;
}

.pagination-req > li:not(:first-child):not(:last-child) {
  display: inline-block;
  margin: 0 5px;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.2;
  color: #ccc;
}

.icon-pagination {
  margin: 0 5px;
}

.next-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: visible;
}

.file-upload-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

.popup-error-message {
  color: red !important;
  font-size: 14px;
  text-align: left !important;
  margin-left: 10px;
}