.header-title {
  color: #493971;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li a {
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #493971;
  color: #fff;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.2;
  color: #ccc;
}

.wfh-approval-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wfh-approval-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
  z-index: 0;
  margin-top:50px;
}

.wfh-approval-table th {
  background-color: #f0f6ff;
  color: #191919;
  padding:15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: center;
}

.wfh-approval-table td {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.76px;
  color: #4C4C4C;
}

.wfh-approval-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.wfh-request-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.icon-pagination {
  margin: 0 5px;
}

.next-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: visible;
}

.wfh-popup-error-message {
  color: red !important;
  font-size: 14px;
  text-align: left !important;
  margin-left: 10px;
}

.wfh-popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.wfh-popup-title {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.wfh-popup-label {
  display: block;
  margin-bottom: 10px;
}

.wfh-popup-input,
.wfh-popup-select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.wfh-popup-buttons {
  margin-top: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.wfh-popup-submit,
.wfh-popup-close {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
}

.wfh-popup-submit {
  background-color: #4caf50;
  color: #fff;
}

.wfh-popup-close {
  background-color: #f44336;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.approve-button:hover {
  background-color: #008000;
  color: white;
}

.reject-button:hover {
  background-color: #cc0000;
  color: white;
}

.input-style{
    border: 2px solid #3498db;
    padding: 8px;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease-in-out
}

.input-style:focus{
    border-color: #2ecc71;
}

.wfh-popup-content {
   max-height: 391px;
   overflow-y: auto;
   border: 2px solid #F0F6FF;
   border-radius: 8px;
   background: #FFFFFF;
   text-align: left;
   width: 100%;
   max-width: 900px;
   display: flex;
   flex-direction: column;
   margin-top: -42%;
   padding: 16px;
}

.wfh-approval-buttons {
  display: flex;
  gap: 8px;
  margin-left: 10%;
}

.wfh-approval-reject-button {
  background-color: #f94141;
  color: white;
  border: 1px solid;
  padding: 5px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.wfh-approval-approve-button {
  background-color: #49e945;
  color: white;
  border: 1px solid;
  padding: 5px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}