.header-title {
  color: #493971;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 10px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li a {
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: #493971;
  color: #fff;
}

.pagination-req {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 30px;
  width:100%;
}

.pagination-req li a {
  color: #667085;
  text-decoration: none;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 14px;
 }

.pagination-req li.active a {
  background-color: #F9F5FF;
  border-radius: 5px;
  padding: 12px 16px;
}

.pagination-req > li:first-child {
  margin-right: auto;
}

.pagination-req > li:last-child {
  margin-left: auto;
}

.pagination-req > li:not(:first-child):not(:last-child) {
  display: inline-block;
  margin: 0 5px;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.2;
  color: #ccc;
}

/*Css for leave request page*/
.leave-request-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x:auto;
}

/*Css for wfh request page*/
.wfh-request-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wfh-request-table {
  width: 100%;
  min-width: 5%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px;
  overflow: hidden;
  z-index: 0;
  margin-top:50px;
}

.wfh-request-table th {
  background-color: #f0f6ff;
  color: #191919;
  padding:15px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: center;
}

.wfh-request-table td {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.76px;
  color: #4C4C4C;
}

.wfh-request-table tbody tr:nth-child(even) {
  background-color: #FAFCFF;
}

.icon-pagination {
  margin: 0 5px;
}

.next-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: visible;
}


